import {useCallback, useEffect, useState} from "react";
import {Stack, Grid} from "@mui/material";
import '../../css/choice.css'
import CurrentQuestion from './CurrentQuestion'
import ControlButton from "./ControlButton";
import {extractNumber} from "../../utils";

export default function PollInteractionPanel({pollDetails, question, answers, newQuestion, lastQuestion, isCat, pollLength, qIdx}) {

    // Initialize answers array to match with questions
    const [selections, setSelections] = useState([]);
    const [answer, setAnswer] = useState({
        reply: '',
        choice_id: null,
        q_id: question.id,
    });

    useEffect(() => {
        const questionNumber = extractNumber(question.id);
        if (questionNumber !== null && answers[questionNumber] !== undefined) {
            // Update answer state if the corresponding answer is found
            setAnswer({
                choice_id: answers[questionNumber].choice_id,
                reply: answers[questionNumber].reply,
                q_id: answers[questionNumber].q_id,
                // You might also want to update choice_id if needed
            });
        }
    }, [question.id, answers]);

    const createSelectedChoices = (question, selections) =>
        question.choices.map(choice => ({
            ch_id: choice.id,
            ch_name: choice.text,
            value: selections.includes(choice.id),
        }));

    const onNext = useCallback(() => {
            let updatedAnswer = {
                ...answer,
                q_id: question.id
            };
            if (!question.multi) {
                newQuestion(updatedAnswer);
            } else {
                let selectedChoices = createSelectedChoices(question, selections);
                console.log('selection', selections, selectedChoices);
                setSelections([]);
                newQuestion({
                    reply: selectedChoices,
                    q_id: question.id,
                });
            }
            const CLEAR_ANSWER = {
                reply: '',
                choice_id: null,
            };
            setAnswer(CLEAR_ANSWER);
        },
        [question, answer, selections]);

    const onBack = useCallback(() => {
        lastQuestion();
    }, [lastQuestion]);

   
    return (
        <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
            <h1 className='black'>{pollDetails.title}</h1>
            <p>{pollDetails.details}</p>
            <hr/>
                <CurrentQuestion question={question}
                                answer={answer}
                                selections={selections}
                                setAnswer={setAnswer}
                                setSelections={setSelections}
                />
            
            <div
                style={{
                    height: '100px', // Adjust height as needed
                    width: '100%',
                }}
            ></div>
            <Stack
                direction="row"
                alignItems="center" // Align items vertically in the center
                justifyContent="space-between" // Space items appropriately
                spacing={2}
                sx={{ width: '100%' }}
                >

                {!isCat && pollDetails && question && (
                    <div
                    className="progress-bar-container"
                    style={{
                        display: 'flex',
                        flexDirection: 'column', // Stack items vertically
                        alignItems: 'center', // Center align horizontally
                        textAlign: 'center', // Align text within the container
                    }}
                >
                    <span className="progress-text" style={{ marginTop: '8px' }}>
                        {`${qIdx + 1}/${pollLength}`}
                    </span>
                    <progress
                        value={qIdx}
                        max={pollLength}
                        className="progress-bar"
                    ></progress>
                    
                </div>
                )}
                <div
                    className="control-buttons-container"
                    style={{
                        flex: '1', // Take up available space
                        display: 'flex', // Use flexbox for buttons
                        justifyContent: 'center', // Center buttons horizontally
                        alignItems: 'center', // Center buttons vertically
                    }}
                >
                    <ControlButton
                        handleClick={onBack}
                        isDisabled={extractNumber(question.id) <= 0 || isCat}
                        buttonText="BACK"
                    />
                    <ControlButton
                        handleClick={onNext}
                        isDisabled={!question.skippable && !answer.reply && selections.length === 0}
                        buttonText="NEXT"
                    />
                </div>
                <div
                    className="invisible-placeholder"
                    style={{
                        flex: '1', // Occupy the right third of the row
                    }}
                ></div>
            </Stack>
        </Stack>
    )
}
import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";
import {getAllPollsApi} from "../../api/apiAnon";
import {handleAuthenticationError, isAdmin} from "../../userAuth";

function TopBar() {

    const [polls, setPolls] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getAllPollsApi('false');
                setPolls(response.data);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
    }, []);

    const getUser = () => {
        const user = localStorage.getItem('email');
        if (user) {
            return <Link to="/account">{user}</Link>;
        }
    };

    return (
<Navbar expand="lg" className="bg-body-tertiary mb-4">
    <Container>
        {/* Branding on the left */}
        <Navbar.Collapse className="justify-content-end">
            <i>🧬 | <tt><b>Genotyper</b>.org</tt></i>
            </Navbar.Collapse>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
            {/* Centered and spaced navigation links */}
            <Nav className="mx-auto justify-content-center">
                <Nav.Link href="/about" className="px-4">About</Nav.Link>
                <Nav.Link href="/genomics" className="px-4">DNA</Nav.Link>
                <Nav.Link href="/" className="px-4">Psychological Testing</Nav.Link>
            </Nav>
        </Navbar.Collapse>

        {/* Login/Sign Up on the right */}
        <Navbar.Collapse className="justify-content-end">
            <div>
                <button className="light-button">
                    {getUser() || (
                        <>
                            <Link to="/signin" className="link">Log In</Link> |{' '}
                            <Link to="/signup" className="link">Sign Up</Link>
                        </>
                    )}
                </button>
            </div>
        </Navbar.Collapse>
    </Container>
</Navbar>


    );
}

export default TopBar;
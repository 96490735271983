import {axios, backendUriDna} from "./config";

export const uploadDNAFile = (formData) => axios.post(`${backendUriDna}/api-dna/uploadDNA`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const cancelDNAFile = (props) => axios.post(`${backendUriDna}/api-dna/DNAcancel`, props);

export const progressState = (props) => axios.post(`${backendUriDna}/api-dna/progressState`, props);
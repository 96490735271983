import {Button} from "@mui/material";
import '../../css/Poll.css';

export default function ControlButton({handleClick, isDisabled, buttonText}) {
    return (
        <Button
            onClick={handleClick}
            disabled={isDisabled}
            className="control-button"
        >{buttonText}</Button>
    );
}

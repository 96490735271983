import {Card} from "react-bootstrap";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import {removePollByShortTitleApi} from "../../api/apiAdmin";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {backendUri} from "../../api/config";
import {getPollResponseByUserApi, getAllUserScoresApi} from "../../api/apiUser";
import { makeMatrix } from "../../utils";
import "../../css/PollCard.css"
import "../../css/global.css"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

export default function PollCard({poll, navigateFunc}) {
    const [hover, setHover] = useState(false);
    const [scoreMatrix, setScoreMatrix] = useState([[]]);
    const [showPopup, setShowPopup] = useState(true);
/*
    useEffect(() => {
        const fetchPolls = async () => {
            try {
                const response2 = await getAllUserScoresApi({token: getToken()});
                setScoreMatrix(makeMatrix(response2.data.scores[poll.short_title]));

            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls', error);
            }

        };

        void fetchPolls();
    }, []);
*/
    function formatScore(scoreVector) {
        if (scoreVector.length === 1) return scoreVector[0].toString();
        return scoreVector.join(", ");
    }

    async function navigateToPoll() {
        await navigateFunc(poll)
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    async function deletePoll(e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete this poll?')) {
            removePollByShortTitleApi(poll.short_title)
                .then(() => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Deletion failed: ', error);
                });
        }
    }

    const cardStyle = {
        maxWidth: '18rem',
        opacity: hover ? 0.9 : 1.0,
        cursor: hover ? 'pointer' : 'auto',
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #ccc',
        boxShadow: hover ? '0 4px 12px rgba(0, 0, 0, 0.2)' : '0 2px 6px rgba(0, 0, 0, 0.1)',
    };
    
    if (!poll.thumbnail) poll.thumbnail = 'logo.png';
    
    return (
        <Card
            onClick={navigateToPoll}
            bg="light"
            className="m-2 text-center p-0 test-card"
            style={cardStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div style={{ paddingTop: '10px' }} >
            <Card.Img 
                variant="top" 
                src={`${backendUri}/images/${poll.thumbnail}`} 
                className="card-image" 
            />
            </div>
            <Card.Body className="d-flex flex-column align-items-start p-3">
                <Card.Title className="fw-bold mb-1 text-start">{poll.title || 'Test Name'}</Card.Title>
                <Card.Text className="text-muted mb-3">{poll.details || 'This is the description of the test.'}</Card.Text>
            </Card.Body>
        </Card>
    );
    
}
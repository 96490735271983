import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import {getAllPollsApi} from "../api/apiAnon";
import {getToken, handleAuthenticationError} from "../userAuth";
import PollCard from "./common/PollCard";
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {checkInfo} from "../api/apiUser";
import PollList from './common/PollList';

export default function Home() {
    const navigate = useNavigate();
    const [polls, setPolls] = useState([]);
    const CACHE_KEY = 'pollsData';

    const [tags, setTags] = useState(['loading...']);

    useEffect(() => {
        async function fetchData() {
            try {
                const cachedData = localStorage.getItem(CACHE_KEY);
                if (cachedData) {
                    console.log('Using cached data');
                    let cached_polls = JSON.parse(cachedData);
                    let gottenTags = [...new Set(cached_polls.map(x => x.tag))];
                    setPolls(cached_polls);
                    setTags(gottenTags)
                }

                const response = await getAllPollsApi('true');
                let gottenTags = [...new Set(response.data.map(x => x.tag))];
                setPolls(response.data);
                setTags(gottenTags);
                localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }
        async function redirInfo() {
            try {
                const response = await checkInfo({token: getToken()});
                if (response.data.message == 'Info not found')
                {
                    navigate('/info');
                }
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
        void redirInfo();
    }, []);

    return (
        <Container className="mt-3">
            <Row className="p-4 pb-3 bg-body-tertiary" style={{borderRadius: '10px'}}>
                <Col md={6} className="d-flex justify-content-center align-items-center">
                    <img 
                        src="/home_scientist.png" 
                        alt="Description" 
                        style={{ width: '75%', borderRadius: '30px' }} 
                    />
                </Col>
                <Col md={6}>
                    <h2 className="black h2-size p-1">Discover Yourself</h2>
                    <p className="black p-size p-1">Take some tests and see how you score.</p>
                    <div style={{ paddingLeft: "6px", paddingTop: "10px" }}>
                        <p style={{ fontSize: "13px" }}>
                            We've carefully curated a collection of psychological assessments that are widely used in academic research and scientific studies. These tests have been validated across large populations and are regularly employed by researchers to understand human behavior, cognition, and personality.
                        </p>

                        <p style={{ fontSize: "13px" }}>
                            While typically restricted to research settings, we're making these professional-grade instruments freely available to help advance psychological science. Each test in our collection has been selected based on its proven reliability and extensive use in peer-reviewed research.
                        </p>

                        <p style={{ fontSize: "13px" }}>
                            After completing a test, you'll receive clear, easy-to-interpret results. Where appropriate, we'll show you how your scores compare to other participants, providing context and percentile rankings to help you understand your results in a meaningful way.
                        </p>
                    </div>
                    <hr />
                </Col>
                <Row className="p-4 pb-0 bg-body-tertiary" style={{ borderRadius: '10px', marginBottom: '0px' }}>
                    <Col md={4}>
                        <h2 className='black' style={{'font-size': '42px'}}>Subjects</h2>
                    </Col>
                </Row>
                <PollList tags={tags} polls={polls}/>
            </Row>
        </Container>
    )
}

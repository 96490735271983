import {FormControlLabel, Radio} from "@mui/material";
import DOMPurify from 'dompurify';
import '../../css/Poll.css';

export default function ChoiceRadio({choice, answer, setAnswer}) {
    function stripHTML(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    }
    const safeHTML = stripHTML(choice.text);
    const isSelected = answer.reply === choice.text;
    return (
                              <button className={`choice-button ${isSelected ? 'selected' : ''}`}
                                     onClick={() => {
                                         setAnswer({
                                             reply: choice.text,
                                             choice_id: choice.id,
                                             q_id: answer.q_id,
                                         });
                                     }} >{safeHTML}</button>
    )
}
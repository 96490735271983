import React, { useState, useEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, OutlinedInput, Box, Checkbox, ListItemText, } from '@mui/material';
import Container from "react-bootstrap/Container";
import { Card} from "react-bootstrap";
import {sendAccountInfo} from "../../api/apiUser";
import {getToken, handleAuthenticationError} from "../../userAuth";
import { useNavigate } from 'react-router-dom';
import { getData } from 'country-list';
import { checkInfo } from '../../api/apiUser';
import Gender from './InfoComponents/Gender';
import Orientation from './InfoComponents/Orientation';
import Birthday from './InfoComponents/Birthday';
import Birthplace from './InfoComponents/Birthplace';
import Ethnicity from './InfoComponents/Ethnicity';
import Nationality from './InfoComponents/Nationality';

export default function Info() {
  let navigate = useNavigate();
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState('1/1/1960');
  const [ethnicity, setEthnicity] = useState([]);
  const [fetish, setFetish] = useState(null);
  const [country, setCountry] = useState([]);
  const [birth, setBirth] = useState([]);
  const [qidx, setQidx] = useState(1);

  useEffect(() => {
      async function fetchData() {
          try {
              let res = await checkInfo({token: getToken()});
              let d = res.data.message;
              if (d != 'Info not found')
              {
                setDob(d.dob);
                setGender(d.gender);
                setEthnicity(d.ethnicity);
                setFetish(d.fetish);
                setCountry(d.country);
                setBirth(d.birth);
              }
          } catch (error) {
              handleAuthenticationError(error);
              console.error('Error fetching data:', error);
          }
      }
      void fetchData();
  }, []);

  const calculateAge = (dob) => {
    const today = new Date(); // Get today's date
    const birthDate = new Date(dob); // Convert `dob` to a Date object

    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the year difference
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }
    return age;
  };

  useEffect(() => {
      if (qidx === 7) {
          sendData();
      }
  }, [qidx]);

  const sendData = async () => {
    const age = calculateAge(dob);
    const data = {
      gender, dob, ethnicity, fetish, country, birth, age
    }
      console.log("Sending data:", {info: data, token: getToken()});
      await sendAccountInfo({info: data, token: getToken()});
      navigate('/');
  };

  const changeQidx = (forward) => {
    if (forward) setQidx(qidx + 1);
    else setQidx(qidx - 1);
  };
  
  

  return (
    <Container>
      <div style={{margin: "0em 2em", borderRadius: '50px'}} className="bg-body-tertiary px-4 py-1">
      {qidx === 1 && <Gender data={gender} setData={setGender} buttonFunc={changeQidx} />}
      {qidx === 2 && <Orientation data={fetish} setData={setFetish} buttonFunc={changeQidx} />}
      {qidx === 3 && <Birthday data={dob} setData={setDob} buttonFunc={changeQidx} />}
      {qidx === 4 && <Ethnicity data={ethnicity} setData={setEthnicity} buttonFunc={changeQidx} />}
      {qidx === 5 && <Birthplace data={birth} setData={setBirth} buttonFunc={changeQidx} />}
      {qidx === 6 && <Nationality data={country} setData={setCountry} buttonFunc={changeQidx} />}
      </div>
    </Container>
  );
}
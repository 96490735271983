import React, { useEffect, useRef, useState } from 'react';
import PollCard from './PollCard';
import {Col, Row} from "react-bootstrap";
import {getToken, handleAuthenticationError} from "../../userAuth";
import {useNavigate} from "react-router-dom";
import {backendUri} from "../../api/config";
import {getPollResponseByUserApi, hasTakenPoll} from "../../api/apiUser";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';



const PollList = ({ tags, polls }) => {
    const navigate = useNavigate();
    const [currentTag, setCurrentTag] = useState(tags[0]); // Start with the first tag
    const tagRefs = useRef({}); // References for each tag section
    const [isCardActive, setIsCardActive] = useState(false);
    const [hasTaken, setHasTaken] = useState(false); 
    const [cardPoll, setCardPoll] = useState({});

    const handleActivateCard = () => setIsCardActive(true);
    const handleDeactivateCard = () => setIsCardActive(false);

    useEffect(() => {
        const observerOptions = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: 0.1, // Trigger when at least 10% of the element is visible
        };

        const observerCallback = (entries) => {
            const visibleTags = entries.filter((entry) => entry.isIntersecting);
            if (visibleTags.length > 0) {
                // Set the tag of the topmost visible section
                console.log('VISIBLE TAGS:', visibleTags)
                setCurrentTag(visibleTags[0].target.dataset.tag);
            }
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observe each tag section
        Object.values(tagRefs.current).forEach((ref) => {
            if (ref) observer.observe(ref);
        });

        // Ensure the initial tag is highlighted on load
        const initialTag = Object.values(tagRefs.current).find(
            (ref) => ref && ref.getBoundingClientRect().top >= 0
        );
        if (initialTag) {
            setCurrentTag(initialTag.dataset.tag);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    async function navigateToPoll(poll) {
            setCardPoll(poll);
            let res = await hasTakenPoll(poll.short_title);
            setHasTaken(res.data.message);
            handleActivateCard();
            /*
            try {
                const data = {short_title: poll.short_title, token: getToken()};
                const res1 = await getPollResponseByUserApi(data);
    
                if (res1.data.res[res1.data.res.length - 1].scores.score !== 'In Progress') {
                    setHasTaken(true);
                }
            } catch (error) {
                handleAuthenticationError(error);
            } finally {
                navigate(targetRoute);
            }*/
    }
    
    const handleTakeTest = () => {
        navigate(`/poll/${cardPoll.short_title}`);
    }

    const handleResults = () => {
        navigate(`/view/${cardPoll.short_title}`);
    }

    return (
        <>
           <Popup
                open={isCardActive}
                closeOnDocumentClick
                onClose={handleDeactivateCard}
                modal
                overlayStyle={{ background: 'transparent'
                                
                 }}
                 contentStyle={{
                    display: 'flex',
                    padding: '20px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '64px', // Makes the container circular
                    background: '#F0F0F0', // Background color for the circle
                    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)', // Optional shadow
                }}
            >
                    <div className="popup-card" >
                        {hasTaken &&
                            <div className="lr-div" >
                                <p className="red" style={{fontSize: "24px"}}>You’ve already taken this test!</p>
                                <div style={{width: "185px"}}></div>
                                <button className="fabulous-button" onClick={handleResults}>
                                    View Results
                                </button>
                            </div>
                        }
                        <h2 className="black">{cardPoll.title}</h2>
                        <p className="body">Time to Complete: 15 min</p>
                        <p className="body">
                            {cardPoll.details}
                        </p>
                        <div className="popup-div">
                            <button className="fabulous-button" onClick={handleTakeTest}>
                                Take The Test
                            </button>
                            <button className="white-button">
                                Learn More
                            </button>
                        </div>
                    </div>
            </Popup>
            <Row className="p-4 pb-3 bg-body-tertiary" style={{ borderRadius: '10px' }}>
                
                <Col md={4}>
                    <div
                        style={{
                            position: 'sticky',
                            top: '20px', // Sticks to the top of the viewport when scrolled
                        }}
                    >
                        {tags.map((tag, index) => (
                            <h2
                                key={index}
                                className={currentTag === tag ? 'text-danger' : ''}
                            >
                                {tag.charAt(0) + tag.slice(1).toLowerCase()}
                            </h2>
                        ))}
                    </div>
                </Col>

                <Col md={8}>
                    {tags.map((tag, index) => (
                        <div
                            key={index}
                            ref={(el) => (tagRefs.current[tag] = el)}
                            data-tag={tag}
                            style={{ marginBottom: '20px', padding: '10px 0' }}
                        >
                            <h3>{tag.charAt(0) + tag.slice(1).toLowerCase()}</h3>
                            <hr />
                            <Row className="p-2 align-items-center justify-content-center">
                                {polls
                                    .filter((poll) => poll.tag === tag)
                                    .map((poll) => (
                                        <PollCard poll={poll} navigateFunc={navigateToPoll} key={poll.title} />
                                    ))}
                            </Row>
                        </div>
                    ))}
                </Col>
            </Row>
        </>
    );
};

export default PollList;
import {React, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import {Card} from "react-bootstrap";
import {loginApi, signupApi} from "../../api/apiAuth";
import {Link} from "react-router-dom";
import AgreementPrompt from "./AgreementPrompt";
import '../../css/Signup.css'

export default function Signup() {
    const [email, setEmail] = useState('josephbronski7@gmail.com');
    const [password, setPassword] = useState('password');
    const [confirmPassword, setConfirmPassord] = useState('password');
    const [name, setName] = useState('Bronski');
    const [success, setSuccess] = useState('');
    const agreementBox = useRef(null);
    const [loading, setLoading] = useState(false);

    const signUp = async () => {
        if (!agreementBox.current.checked) {
            setSuccess("Please agree to the terms and conditions!");
            return;
        }
        if (password !== confirmPassword) {
            setSuccess("Your passwords do not match!");
            return;
        }
        setLoading(true);
        const data = {
            name: name,
            email: email,
            password: password,
        };
        try {
            await signupApi(data);
            alert("Signup succeeded. A verification email has been sent. You must verify before logging in.");
            window.location = '/';
        } catch (e) {
            setSuccess("Failed!");
            console.log(e);
            alert("An error occurred: " + e.response.data.message);
        } finally {
            setLoading(false);
        }
        
    };

    return (
        <div className="container-div">
                <div className="signup-div">
                    <h2 className="red">Sign Up</h2>
                    <div className="Auth-form-container">
                        <form className="Auth-form">
                            <div className="Auth-form-content">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control signup-input"
                                        placeholder="Name"
                                        id="firstName"
                                        name="firstName"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        className="form-control signup-input"
                                        placeholder="Email"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control signup-input"
                                        placeholder="Password"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className="form-control signup-input"
                                        placeholder="Confirm Password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassord(e.target.value)}
                                        required
                                    />
                                </div>
                                <AgreementPrompt checkboxRef={agreementBox} />
                                <div className="form-group mt-3">
                                    <button
                                        type="submit"
                                        className="btn signup-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            void signUp();
                                        }}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            'Sign Up'
                                        )}
                                    </button>
                                </div>
                                {success && <p className="success-message">{success}</p>}
                            </div>
                        </form>
                    </div>
                    <div className="signin-link">
                        Already have an account? <Link to="/signin">Log In</Link>
                    </div>
                </div>
        </div>
    );
    
}